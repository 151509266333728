<template>
  <div>
    <div class="page-content py-5 pt-0">
      <!--slider-->
      <div class="quran-container">
        <div class="quran-slider-container-bg">
          <div class="container text-white">
            <div
              id="quranProjectCarousel"
              class="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row py-0">
                    <div class="col-12 col-lg-10 row m-auto">
                      <div class="col-12 col-lg-5 pt-5 text-center my-auto">
                        <img
                          data-animation="animated bounceInDown"
                          src="@/assets/images/quraan-mic.png"
                          class="img-fluid h-100"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div class="col-12 col-lg-6 my-auto">
                        <h2
                          class="p-0 m-0 mb-2"
                          data-animation="animated bounceInUp"
                        >
                          إذاعات القران الكريم
                        </h2>
                        <div
                          class="info lh-lg text-justify"
                          data-animation="animated zoomInLeft"
                        >
                          هو مشروع إلكتروني يقوم على نشر القرآن الكريم وعلومه
                          وترجماته بأكثر من 50 لغة عالمية وعبر أكثر من 125 إذاعة
                          إلكترونية. يعمل المشروع من خلال 3 مواقع إلكترونية و3
                          تطبيقات للهاتف تبث القرآن الكريم على مدار الساعة وهذه
                          التطبيقات متاحة للتحميل من على متاجر الهواتف الذكية
                          والأجهزة اللوحية بالمجان.
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--//carousel-item1-content-->
                </div>
                <!--//carousel-item1-->
                <div class="carousel-item">
                  <div class="row py-0">
                    <div class="col-12 col-lg-10 row m-auto">
                      <div class="col-12 col-lg-6 my-auto">
                        <h2
                          class="p-0 m-0 mb-2"
                          data-animation="animated bounceInRight"
                        >
                          إذاعات القران الكريم
                        </h2>
                        <div
                          class="info lh-lg text-justify"
                          data-animation="animated zoomInRight"
                        >
                          حقق هذا المشروع بحمد الله أكثر من 100 مليون دقيقة
                          استماع من خلال أكثر من 7 ملايين مستمع من 210 دولة حول
                          العالم.
                          <br />
                          وقد حصد هذا المشروع المبارك العديد من الجوائز المحلية
                          والإقليمية والعالمية.
                        </div>
                      </div>
                      <div class="col-12 col-lg-5 pt-5 text-center my-auto">
                        <img
                          data-animation="animated bounceInLeft"
                          src="@/assets/images/slider/quran-award.png"
                          class="img-fluid h-100"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                  <!--//carousel-item2-content-->
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#quranProjectCarousel"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#quranProjectCarousel"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--// slider-->

      <div class="quran-about-container overflow-hidden">
        <div class="quran-about-box py-5">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 text-center">
                <img
                  src="@/assets/images/quraan-mp3.png"
                  class="w-75"
                  data-aos="fade-left"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="col-12 col-md-12 col-lg-6">
                <h1 class="h4" data-aos="zoom-in">عن المشروع</h1>
                <div class="info text-justify" data-aos="fade-in">
                  إن خدمة القرآن الكريم شرف وأيما شرف وهي حسبة نحتسبها عند الله
                  تعالى وبحمد الله تعالى نحن نخدم القرآن الكريم، فهو طريق الفلاح
                  في الدنيا والآخرة، وهو خير تجارة محفوظة من الهلاك والبوار. وقد
                  حرصت اللجنة على توفير خدمة الاستماع إلى آيات الله البينات على
                  مدار الساعة عبر شبكة الإنترنت بأكثر من أربعون لغة تصل إلى أقصى
                  بقاع الأرض كما توفر اللجنة ذات الخدمات لتفسير آيات الله تعالى
                  لأكبر وأشهر المفسرون الذين يتوفر لهم تفسير مرئي أو مسموع، وقد
                  أصبح هذا المشروع بفضل الله وتوفيقه مرجعا أساسيا في نشر علوم
                  وترجمات القرآن الكريم سواء فيما يخص المواقع الإلكترونية أو
                  التطبيقات الذكية، حيث رصدت اللجنة بحمد الله قيام العديد من
                  اللجان المحلية والإقليمية تقوم بإعادة نشر محتوى اللجنة المتميز
                  للمشروع.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="quran-about-box py-5 edc-bg-primary">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6">
                <ul class="liststyle-none mt-3" data-aos="fade-left">
                  <li class="mt-3 d-flex" data-aos="fade-right">
                    <i class="fa fa-check-double"></i>
                    <span class="mx-2 d-inline"
                      >يحتوي على موقع إلكتروني متخصص للقرآن الكريم وعلومه
                      وترجماته يحتوي على ترجمات بـ 40 لغة عالمية منها 12 لغة
                      غربية و22 لغة آسيوية و6 لغات إفريقية
                    </span>
                  </li>
                  <li class="mt-3 d-flex" data-aos="fade-right">
                    <i class="fa fa-check-double"></i>
                    <span class="mx-2 d-inline">
                      يحتوي الموقع على 6 إذاعات لتفسير القرآن الكريم منها تفسير
                      القرآن الكريم بالروسية للشيخ السعدي، وإذاعة للرقية
                      الشرعية، و25 إذاعة لقراءات القرآن الكريم المتواترة، و42
                      تلاوة لرواية حفص عن عاصم لمشاهير وأعلام القراء العرب.
                    </span>
                  </li>
                  <li class="mt-3 d-flex" data-aos="fade-right">
                    <i class="fa fa-check-double"></i>
                    <span class="mx-2 d-inline"
                      >حقق المشروع بحمد الله أكثر من 100 مليون دقيقة استماع من
                      خلال أكثر من 7 ملايين مستمع من 210 دولة حول العالم.</span
                    >
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-12 col-lg-6 text-center">
                <img
                  src="@/assets/images/quraan-wave.png"
                  class="w-75 mt-5"
                  data-aos="fade-left"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Goals-->
      <div class="quran-goals-container overflow-hidden py-5">
        <div class="container">
          <div class="quran-goals-box row">
            <h4
              class="
                quran-goals-box-right-title
                p-0
                edc-section-title
                fw-bold
                edc-color-primary
                fs-2
              "
            >
              أهداف المشروع
            </h4>
            <div class="col-12">
              <div class="container p-0">
                <div class="row p-0" style="overflow: hidden">
                  <!--box-->
                  <div
                    class="col-12 col-lg-4 mb-3 col-md-6 text-center"
                    data-aos="fade-up"
                  >
                    <div class="bg-white rounded-30 border-1 py-4 px-3">
                      <div class="icon m-2">
                        <img src="@/assets/images/quran.png" />
                      </div>
                      <div class="text">
                        <h1 class="h5 edc-color-primary">نشر القرآن الكريم</h1>
                        <p class="text-muted">
                          إلى أكبر شريحة من الجمهور بمختلف ثقافاته.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                  <!--box-->
                  <div
                    class="col-12 col-lg-4 mb-3 col-md-6 text-center"
                    data-aos="fade-right"
                  >
                    <div class="bg-white rounded-30 border-1 py-4 px-3">
                      <div class="icon m-2">
                        <img src="@/assets/images/muslim-icon.png" />
                      </div>
                      <div class="text">
                        <h1 class="h5 edc-color-primary">تعريف غير المسلمين</h1>
                        <p class="text-muted">
                          بتعاليم القرآن من خلال ترجمة معانيه بلغاتهم.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                  <!--box-->
                  <div
                    class="col-12 col-lg-4 mb-3 col-md-6 text-center"
                    data-aos="fade-down"
                  >
                    <div class="bg-white rounded-30 border-1 py-4 px-3">
                      <div class="icon m-2">
                        <img src="@/assets/images/mic-head.png" />
                      </div>
                      <div class="text">
                        <h1 class="h5 edc-color-primary">نشر ترجمات وعلوم</h1>
                        <p class="text-muted">
                          وتفسير القرآن الكريم إلى أكثر من 249 دولة وإقليم.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--//box-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--//Goals-->
      <!--status-->
      <div class="quran-status-container overflow-hidden bg-white py-5">
        <div class="container">
          <h4
            class="
              quran-status-title
              edc-color-primary
              fw-bold
              fs-2
              mb-3
              edc-section-title
            "
          >
            إحصائيات الإذاعات
          </h4>
          <!--box one-->
          <div class="quran-status-blocks overflow-hidden row">
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="flip-left"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <i class="fa fa-podcast fs-rem-5-5 mb-3" aria-hidden="true"></i>
                <h4 class="mb-3 fs-3">اجمالي الزيارات</h4>
                <div class="quran-status-block-count fs-2">
                  <count-up
                    v-if="status.quran_radios"
                    :endVal="parseInt(status.quran_radios.visits)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box one-->
            <!--box tow-->
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <i class="fas fa-cloud-download-alt fs-rem-5-5 mb-3"></i>
                <h4 class="mb-3 fs-3">اجمالي دقائق الاستماع</h4>
                <div class="quran-status-block-count fs-2">
                  <count-up
                    v-if="status.quran_radios"
                    :endVal="parseInt(status.quran_radios.minutes)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box tow-->
            <!--box three-->
            <div
              class="col-sm-12 col-xl-4 col-md-6 mb-2"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <div class="rounded-30 edc-bg-primary text-center py-5">
                <i class="fas fa-headphones-alt fs-rem-5-5 mb-3"></i>
                <h4 class="mb-3 fs-3">عدد الاذاعات</h4>
                <div class="m-countup quran-status-block-count fs-2">
                  <count-up
                    v-if="status.quran_radios"
                    :endVal="parseInt(status.quran_radios.radios_total)"
                    >+</count-up
                  >
                </div>
              </div>
            </div>
            <!--// box three-->
          </div>
        </div>
      </div>
      <!--//status-->

      <!--Tools-->
      <div class="quran-achievements-container overflow-hidden py-5">
        <div class="container">
          <h4 class="edc-color-primary fw-bold fs-3 mb-3 edc-section-title">
            أدوات المشروع
          </h4>
          <div class="quran-achievments-box row">
            <!-- boxOne-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="quran-achievments-item-thumble text-center">
                  <img
                    src="@/assets/images/quran-staus.png"
                    class="
                      quran-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  class="quran-achievments-item-title border-1 border-bottom"
                >
                  <h1
                    class="
                      h4
                      py-3
                      d-block
                      color-dark
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <span>شاشة الإحصائيات</span>
                    <div>
                      <a
                        href="http://stats2.quraan.us/edc/screen.php"
                        target="_blank"
                      >
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </div>
                  </h1>
                </div>
                <div class="quran-achievments-item-chanel">
                  <h3 class="h5 text-muted lh-5 py-3">
                    تعمل هذه الشاشة على عرض جميع مخرجات المشروع على مدار الساعة
                    بشكل تلقائي، وتم عرض هذه المخرجات بصورة سهلة لتمكن المستخدم
                    والزائر من مطالعة كافة أرقام وإحصائيات المشروع.
                  </h3>
                </div>
              </div>
            </div>
            <!--// boxOne-->
            <!-- boxTow-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="zoom-in">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="quran-achievments-item-thumble text-center">
                  <img
                    src="@/assets/images/quran-mobile.png"
                    class="
                      quran-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  class="quran-achievments-item-title border-1 border-bottom"
                >
                  <h1
                    class="
                      h4
                      py-3
                      d-block
                      color-dark
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <span>تطبيقات الهاتف</span>
                    <div class="app-icon">
                      <a
                        href="https://bit.ly/3Ii0n1T"
                        class="mx-1 text-success"
                        target="_blank"
                        ><i class="fab fa-android"></i
                      ></a>
                      <a
                        href="https://bit.ly/3w8OVDE"
                        class="text-gray-300"
                        target="_blank"
                        ><i class="fab fa-apple"></i
                      ></a>
                    </div>
                  </h1>
                </div>
                <div class="quran-achievments-item-chanel">
                  <h3 class="h5 text-muted lh-5 py-3">
                    توفر اللجنة عدد 3 تطبيقات للهواتف الذكية والأجهزة اللوحية
                    تبث القرآن الكريم على مدار الساعة، وهذه التطبيقات متاحة
                    للتحميل من على متاجر الهواتف الذكية والأجهزة اللوحية
                    بالمجان.
                  </h3>
                </div>
              </div>
            </div>
            <!--// boxTow-->
            <!-- boxThree-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="quran-achievments-item-thumble text-center">
                  <img
                    src="@/assets/images/quran-website.png"
                    class="
                      quran-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  class="quran-achievments-item-title border-1 border-bottom"
                >
                  <h1
                    class="
                      h4
                      py-3
                      d-block
                      color-dark
                      d-flex
                      justify-content-between
                    "
                  >
                    <span>مواقع إلكترونية </span>
                    <div>
                      <a href="http://quran.com.kw/" target="_blank">
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </div>
                  </h1>
                </div>
                <div class="quran-achievments-item-chanel">
                  <h3 class="h5 text-muted lh-5 py-3">
                    توفر اللجنة 3 مواقع لخدمة القرآن الكريم، محمل عليها القرآن
                    الكريم مسموعا مرتلا ومجودا حسب الصورة والآية، وب 51 لغة
                    عالمية يمكن الاستماع إليها من أي مكان حول العالم.
                  </h3>
                </div>
              </div>
            </div>
            <!--// boxThree-->
          </div>
        </div>
      </div>
      <!--//Tools-->
      <!--Awards -->
      <div class="quran-achievements-container overflow-hidden py-5">
        <div class="container">
          <h4 class="edc-color-primary fw-bold fs-3 mb-3 edc-section-title">
            جوائز المشروع
          </h4>
          <div class="quran-achievments-box overflow-hidden row">
            <!-- boxOne-->
            <div
              class="col-sm-12 col-xl-6 col-md-6 mb-2"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                  d-md-flex d-md-flex
                  flex-lg-nowrap flex-sm-wrap
                "
              >
                <div class="quran-achievments-item-thumble w-md-50 text-center">
                  <img
                    src="@/assets/images/award/unisco.jpg"
                    class="
                      quran-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="quran-achievments-item-title mx-2 w-md-50">
                  <h1 class="h4 py-3 d-block color-dark text-justify">
                    جائزة القمة المعلوماتية برعاية اليونيسكو في مجال الاحتواء
                    والتمكين لعام 2016
                  </h1>
                </div>
              </div>
            </div>
            <!--// boxOne-->
            <!-- boxTow-->
            <div class="col-sm-12 col-xl-6 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                  d-md-flex d-md-flex
                  flex-lg-nowrap flex-sm-wrap
                "
              >
                <div class="quran-achievments-item-thumble w-md-50 text-center">
                  <img
                    src="@/assets/images/award/kfas.jpg"
                    class="
                      quran-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="quran-achievments-item-title mx-2 w-md-50">
                  <h1 class="h5 py-3 d-block color-dark text-justify">
                    المركز الأول في جائزة الكويت الإلكترونية التي تقدمها مؤسسة
                    الكويت للتقدم العلمي – الدورة السادسة 2015-2016.
                  </h1>
                </div>
              </div>
            </div>
            <!--// boxTow-->
            <!-- boxThree-->
            <div class="col-sm-12 col-xl-6 col-md-6 mb-2" data-aos="fade-right">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                  d-md-flex d-md-flex
                  flex-lg-nowrap flex-sm-wrap
                "
              >
                <div class="quran-achievments-item-thumble w-md-50 text-center">
                  <img
                    src="@/assets/images/award/tech-inn.jpg"
                    class="
                      quran-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="quran-achievments-item-title mx-2 w-md-50">
                  <h1 class="h5 py-3 d-block color-dark text-justify">
                    وسام الإبداع – المركز الثاني كأفضل تطبيق للأجهزة الذكية عن
                    تطبيق إذاعات القرآن الكريم (مؤتمر الإبداع التقني في العمل
                    الخيري، البحرين 2016)
                  </h1>
                </div>
              </div>
            </div>
            <!--// boxThree-->
            <!-- boxFoure-->
            <div class="col-sm-12 col-xl-6 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                  d-md-flex
                  flex-lg-nowrap flex-sm-wrap
                "
              >
                <div class="quran-achievments-item-thumble w-md-50 text-center">
                  <img
                    src="@/assets/images/award/nune.jpg"
                    class="
                      quran-achievments-thumble-img
                      rounded-30
                      img-fluid
                      w-100
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="quran-achievments-item-title mx-2 w-md-50">
                  <h1 class="h5 py-3 d-block color-dark text-justify">
                    ترشيح موقع إذاعات القرآن الكريم كأفضل موقع لخدمة القرآن
                    الكريم في مبادرة "نون" بالتعاون مع برنامج "بالقرآن اهتديت"
                    للشيخ فهد الكندري (موسم 2016)
                  </h1>
                </div>
              </div>
            </div>
            <!--// boxFoure-->
          </div>
        </div>
      </div>
      <!--//Awards -->
      <!--Translation-->
      <div class="quran-achievements-container overflow-hidden py-5">
        <div class="container">
          <h4 class="edc-color-primary fw-bold fs-3 mb-3 edc-section-title">
            بعض من اذاعتنا
          </h4>
          <div class="quran-achievments-box row">
            <!-- boxOne-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="quran-achievments-item-thumble text-center">
                  <a href="https://bit.ly/3KOH7Lf" target="_blnak">
                    <img
                      src="@/assets/images/radios/abedelbaset.jpg"
                      class="
                        quran-achievments-thumble-img
                        rounded-30
                        img-fluid
                        w-100
                      "
                      alt=""
                      srcset=""
                    />
                  </a>
                </div>
                <div class="quran-achievments-item-title">
                  <a
                    href="https://bit.ly/3KOH7Lf"
                    target="_blnak"
                    class="h4 py-3 d-block color-dark"
                  >
                    إذاعة الشيخ عبد الباسط عبد الصمد
                  </a>
                </div>
                <div class="quran-achievments-item-icons p-2 row">
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/soundcloud.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/apple-podcasts.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/google-podcasts.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!--// boxOne-->
            <!-- boxTow-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="zoom-in">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="quran-achievments-item-thumble text-center">
                  <a href="https://bit.ly/360bGic" target="_blank">
                    <img
                      src="@/assets/images/radios/tagalog.jpg"
                      class="
                        quran-achievments-thumble-img
                        rounded-30
                        img-fluid
                        w-100
                      "
                      alt=""
                      srcset=""
                    />
                  </a>
                </div>
                <div class="quran-achievments-item-title">
                  <a
                    href="https://bit.ly/360bGic"
                    target="_blank"
                    class="h4 py-3 d-block color-dark"
                    >إذاعة ترجمة القرآن بالتجالوج</a
                  >
                </div>
                <div class="quran-achievments-item-icons p-2 row">
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/soundcloud.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/apple-podcasts.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/google-podcasts.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!--// boxTow-->
            <!-- boxThree-->
            <div class="col-sm-12 col-xl-4 col-md-6 mb-2" data-aos="fade-left">
              <div
                class="
                  quran-achievments-item
                  rounded-30
                  p-2
                  pb-4
                  edc-content-round
                "
              >
                <div class="quran-achievments-item-thumble text-center">
                  <a href="https://bit.ly/3JjE6SN" target="_blnak">
                    <img
                      src="@/assets/images/radios/amazig.jpg"
                      class="
                        quran-achievments-thumble-img
                        rounded-30
                        img-fluid
                        w-100
                      "
                      alt=""
                      srcset=""
                    />
                  </a>
                </div>
                <div class="quran-achievments-item-title">
                  <a
                    href="https://bit.ly/3JjE6SN"
                    target="_blank"
                    class="h4 py-3 d-block color-dark"
                    >إذاعة ترجمة القرآن بالأمازيغية</a
                  >
                </div>

                <div class="quran-achievments-item-icons p-2 row">
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/soundcloud.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/apple-podcasts.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a
                      href="#"
                      class="
                        edc-border-primary
                        mb-3
                        py-1
                        rounded-20
                        text-center
                        bg-white
                        d-block
                      "
                    >
                      <img
                        src="@/assets/images/google-podcasts.svg"
                        alt=""
                        srcset=""
                        width="w-100"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!--// boxThree-->
          </div>
        </div>
      </div>
      <!--//Translation-->

      <testimonial
        :head_title="`title.testimonial_quran`"
        section="quran"
      ></testimonial>
      <!--donate-->
      <div class="quran-donate-container overflow-hidden bg-white py-5">
        <div
          class="container d-flex justify-content-between align-items-center"
        >
          <div class="quran-donate-title">
            <h4 class="py-2 fs-2">
              {{ $t("label.donate_to_project") }}
            </h4>
          </div>
          <div class="quran-donate-button" data-aos="zoom-in">
            <router-link
              :to="{ path: '/products/مشروع-تعليم-ونشر-القرآن-الكريم-2/' }"
              class="btn edc-btn-secondary text-white p-2 px-5 rounded-20 fs-5"
            >
              {{ $t("btn.donate_now") }}
              <i class="fas fa-hand-holding-usd"></i>
            </router-link>
          </div>
        </div>
      </div>
      <!--//donate-->
    </div>
  </div>
</template>

<script>
import Testimonial from "../../components/Testimonial.vue";
import { mapActions, mapGetters } from "vuex";
import ogImage from "@/assets/images/logo.svg";
export default {
  components: { Testimonial },
  name: "QuranRadios",
  computed: {
    ...mapGetters("project_status", ["status"]),
  },
  methods: {
    ...mapActions("project_status", ["getStatusValue"]),
  },
  created() {
    this.getStatusValue();
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.quran_radios") + " | %s",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("title.quran_radios"),
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:url", property: "og:url", content: window.location.href },
        {
          vmid: "og:image",
          property: "og:image",
          content: window.location.origin + ogImage || "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>